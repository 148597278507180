<template>
  <div>
    <b-card class="updates-card">
      <h1 class="updates-title">
        <div class="updates-date">Date: 05 February 2025</div>
        Here’s what’s new:
      </h1>
      <ol class="content">
        <li>
          <strong>Mark Absent While Updating Activity Marks:</strong>
          <p>• Instructors can now mark students as <strong>Absent</strong> while updating marks for activities such as
            assignments and assessments.</p>
          <p>• To mark a student absent, simply use the <strong>Absent switch</strong> located on the left side of
            student names.</p>
        </li>
        <li>
          <strong>Enhanced Consolidated Statement of Internal Marks:</strong>
          <p>• The report will now display additional insights, including:</p>
          <ul class="bordered-dot-list">
            <strong>
              <li>Highest Marks in Class</li>
              <li>Lowest Marks in Class</li>
              <li>Class Average Marks</li>
            </strong>
          </ul>
        </li>
        <li>
          <strong>Customizable Feedback Survey Report Font Size:</strong>
          <p>• HODs/Program Coordinators can now adjust the font size of Feedback Survey reports through program
            configuration.</p>
          <p>• This update will allow users to adjust the font size to fit the entire report on a single page.</p>
          <p><strong>Path:</strong> 'Department/Program' Tab > Expand ‘Show Program’ against department name > Click on
            'Program Name' to view Program configuration > ‘Instructor Feedback Survey Report Font Size Settings' Tab >
            Set Size as required.</p>
        </li>
        <li>
          <strong>Department/Program Vision and Mission Update:</strong>
          <p>• A new option has been added to update the vision and mission of a department or program.</p>
          <p><strong>Path:</strong> 'Department/Program' Tab > Expand ‘Show Program’ against department name > Click on
            'Program Name' > 'Vision And Mission' Tab > Update details.</p>
        </li>
      </ol>
    </b-card>

    <b-card class="updates-card">
      <h1 class="updates-title">
        <div class="updates-date">Date: 13 January 2025</div>
        Student Profile and Time table
      </h1>
      <ol class="content">
        <li>
          <strong>Delivery Method and Class Type in Timetable:</strong>
          <p>•Class Advisors can now add <strong>Delivery Method</strong> (e.g., Lecture, Tutorial) and Class Type
            (e.g., Extra Class, Remedial Class) for each slot in the timetable.</p>
          <p>•The average value of CO-PO/PSO mapping will now be displayed.</p>
        </li>
        <li>
          <strong>Enhanced Student Profile Access:</strong>
          <p>•Class Advisors can now access individual student profiles directly from the <strong>Enrolled Student
              List</strong>:</p>
          <p><strong>Path:</strong>'Classes/Courses' Tab > 'Enroll Students' (against the class name in the class list)
            > 'Enrolled Student List' Tab > Profile Icon (against each student’s name).</p>
          <p>•The student profile includes:</p>
          <ul class="bordered-dot-list">
            <li>Basic student information</li>
            <li>Sessional and internal marks obtained for each semester</li>
          </ul>
          <p>
            <strong>•Download Option:</strong> Student profiles can be downloaded using the <strong>'Download'</strong>
            button located at the top-right corner of the student profile page.
          </p>
        </li>
      </ol>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  created() {
    this.markUpdateViewedat(); // Send request only when expanding
  },
  methods: {
    async markUpdateViewedat() {
      const nowInKolkata = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/institutionuser/setting/ezygoapp_update_viewed_at`;
      try {
        await axios.post(url, { ezygoapp_update_viewed_at: nowInKolkata });
      } catch (error) {
        error;
      }
    }
  },
};
</script>

<style scoped>
.updates-card {
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fafafa;
}

.updates-date {
  text-align: left;
  color: red;
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  margin-bottom: 10px;
}

.content {
  font-size: 16px;
  line-height: 1.6;
  color: #555;
}

.content-heading {
  text-decoration: underline;
  font-weight: bold;
}

.italic {
  font-style: italic;
}
</style>
